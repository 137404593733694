@import '../App/style-variables.scss';

.store-location,
.store-number {
  color: $rgis-medium-gray;
  font-size: 1rem;
}

.store-address {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.store-info-icon {
  vertical-align: bottom;
  margin-left: -3px;
}
.vehicle-icon {
  margin-top: 15px;
  vertical-align: middle;
  margin-left: -3px;
}

.store-title {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.vehicle-type{
  margin-left:25px;
  margin-top: -15px;
}

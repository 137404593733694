@import '../../components/App/style-variables.scss';

.post-card-comments{
  .add-comment-container {
    padding: 5px 15px 15px;

    textarea {
      width: 100%;
    }
  }

  .no-new-comments-msg {
    font-style: italic;
  }

  .comments-list {
    .comments-list-item {
      position: relative;

      .comment-body {
        white-space: pre-wrap;
      }

      .comment-actions, .reply-action{
        position: absolute;
        right: 0;
        margin-right: -15px;
        margin-top: -14px;

        .dropdown-menu {
          z-index: 9;
        }

        .comment-actions-button, .reply-action-button {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: $rgis-light-gray;
          }
        }
  
        img{
          height: 18px;
          cursor: pointer;
        }
      }


      .reply-action{
        position: absolute;
        right: 0;
        margin-right: 30px;
        margin-top: -14px;
      }

      .flag-comment{
        position: absolute;
        right: 0;
        margin-right: 75px;
        margin-top: -14px;

        div{
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      .replies-number{
        &:hover {
          background-color: $rgis-light-gray;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .post-card-comments{
    .add-comment-container {
      textarea {
        width: 95%;
      }
    }
  }
}
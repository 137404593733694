@import '../App/style-variables.scss';



.time-punch-item {
  cursor: pointer;
  .job-name {
    font-size: 18px;
    font-weight: 600;
  }

  i.check-circle, i.msr{
    color: $rgis-blue;
  }
}

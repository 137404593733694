@import '../../components/App/style-variables.scss';

.news-container{
  font-size: 16px;
  padding-top: 60px;

  .news-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }
}
@import '../App/style-variables.scss';

.aset-info {
  .col {
    display: flex;
    justify-content: center;
    text-align: center;

    &.title {
      justify-content: left;
      text-align: left;
    }
  }

  .aset-vertical {
    height: 200px;
    padding-bottom: 5px;

    .col {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .rangeBar {
      border: 3px solid #9f9fa0;
      border-radius: 4px;
      margin: 5px 0;
      width: 4px;

      &.rangeBar-sm {
        height: 30%;
      }
      &.rangeBar-md {
        height: 40%;
      }
      &.rangeBar-lg {
        height: 50%;
      }
      &.rangeBar-xl {
        height: 60%;
      }
    }

    .circle {
      align-items: center;
      border: 2px solid #9f9fa0;
      border-radius: 22px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      height: 44px;
      justify-content: center;
      padding: 5px;
      margin-top: 5px;
      width: 44px;
    }
  }

  .range-active {
    color: $rgis-blue;
    font-weight: 600;

    .circle,
    .rangeBar {
      border-color: $rgis-blue;
    }
  }
}

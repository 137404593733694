@import '../../App/style-variables.scss';

.channel-list-item {
  .card {
    margin-bottom: 10px;
    
    &.has-new-posts {
      border-bottom: 3px solid $rgis-green;
    }

    &:hover {
      background-color: $rgis-light-gray;
      cursor: pointer;
    }

    .card-body {
      padding: 12px 16px 16px;
    }

    a {
      color: $rgis-black;
      text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }
}

@import '../App/style-variables.scss';

.team-details {
  .title {
    padding: 0 0 13px;
  }

  .meet-time {
    font-size: 18px;
  }

  .backroom-indicator {
    color: $rgis-medium-gray;
    font-size: 1rem;
  }
}

.team-details-component {
  min-height: 200px;
  background-color: white;

  .btn-tab {
    background: $rgis-background;
    border-radius: 1px;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none !important;
    box-shadow: none !important;
    span {
      background: #000;
      color: #fff;
      font-weight: bold;
      padding: 2px 4px;
      border-radius: 4px;
      margin-left: 5px;
    }
  }
  .btn-tab.active {
    background: $rgis-red;
    color: #fff;
    span {
      background: #fff;
      color: #000;
    }
  }
}

/* Variables */
@import '../../App/style-variables.scss';

.communication-preferences-component {
  
    .availability-edit-link {
        color: $rgis-blue !important;
    }

    .general-title {
        color: $rgis-medium-gray;
        font-size: 16px;
        padding: 14px 18px 14px 0px;
    }

    .communication-preferences-link {
        color: #0072cb !important;
    }

    .bold-text {
        font-weight: bolder;
    }
}
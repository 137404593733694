@import '../../components/App/style-variables.scss';

.my-team-container{
  font-size: 16px;
  padding-top: 60px;

  .my-team-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

 
  
  .material-icons {
    &.add_box,
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .my-team-details {

    .my-team-width{ 
      width: 75%;    
    }

    .card {
      margin-bottom: 10px;
      .card-body {
        padding: 12px 16px 16px;
      }

      a:not(.phone-number) {
        color: $rgis-black;
        text-decoration: none;
      }

      .phone-number{
        a{
          color: #007bff;
          text-decoration: none;
          background-color: transparent;
        }
      }
  
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
      }
    }
  }
}
  
@media only screen and (max-width: 768px) {
  .my-team-container{
    .my-team-width{ 
      width: 100%;    
    }
  }
}
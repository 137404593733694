@import '../../components/App/style-variables.scss';

.time-punches-content {
  border-top: 1px solid #999999;
  font-size: 16px;
  padding-top: 60px;
  position: relative;

  .card {
    margin: 9px 0;
  }

  .timepunches-date-interval{
    display: flex;
    justify-content: flex-end;
    font-weight: 800;
    margin: 16px 0 0;
  }

  .schedule-item-date {
    display: flex;
    font-size: 14px;
    justify-content: left;

    &.bold {
      font-weight: 1000;
    }
  }
}

@media screen and(max-width: 1024px) {
  .time-punches-content{
    padding-top: 100px;
  }
}

/* Variables */
@import '../App/style-variables.scss';

.other-availability-list-component {
    .general-title {
        color: $rgis-medium-gray;
        font-size: 16px;
        padding: 14px 18px 14px 16px;
    }
        
    .other-availability-link {
        color: $rgis-blue !important;
    }

    .other-avail-list-item {
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .other-avail-time-separator {
        padding-left: 10px;
        padding-right: 10px;
      }

    .btn-rgis-blue {
        background: #0072cb;
        color: #fff;
        border-radius: 24px;
    }
}

.react-datepicker {
    position: relative !important;
}
/* Variables */

@import '../App/style-variables.scss';
.preloader-component {
  @media (min-width: 992px) {
    .preloader img {
      width: 200px !important;
    }
  }
  @media (min-width: 1200px) {
    .preloader img {
      width: 200px !important;
    }
    .loader-logo-wrapper {
      margin-top: 15% !important;
    }
  } //colors
  $black_40: rgba(0, 0, 0, 0.4);
  $white: #fff;
  .preloader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: $black_40;
    height: 100%;
    width: 100%;
    display: none;
    &.show {
      display: block;
    }
    &.inside-div{
      position: absolute;
    }
    img {
      width: 200px;
    }
  }
  .loader-logo-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 50%;
  }
  .preloader-caption {
    color: $white;
    margin-top: 30px;
    text-align: center;
  }

  .spinning-circle {
    border: 5px solid $rgis-blue; 
    border-top: 5px solid $rgis-black; 
    border-bottom: 5px solid $rgis-black; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: auto;
    animation: spin .75s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

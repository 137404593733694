/* Variables */

@import '../../App/style-variables.scss';
.language-component {
  float: right;
  .rgis-flags.flag-select {
    color: white;
  }
  .rgis-flags.flag-select .arrow-down {
    color: white;
    padding: 8px;
  }
  .rgis-flags.flag-select img {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: 0;
  }
  .continent {
    color: #000000;
    padding-left: 8px;
    font-size: 1.2em;
  }
  .rgis-flags.flag-select .flag-option.has-label {
    color: #000000;
  }
  .navbar-right {
    float: right;
    margin-top: 20px;
    margin-right: 10px;
  }
  .flag-select {
    position: relative;
    display: inline-block;
    vertical-align: inherit;
    margin: 15px 55px 15px 0px;
    color: #4d4d4d;
    text-align: left;
  }
  .flag-select img {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: 0.3em;
  }
  .flag-select .selected--flag--option {
    cursor: pointer;
    padding: 0 8px;
  }
  .flag-select .selected--flag--option:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
  .flag-select .selected--flag--option:focus {
    outline: none;
  }
  .flag-select .selected--flag--option .country-label {
    padding-left: 9px;
    text-transform: capitalize;
  }
  .flag-select .country-flag {
    cursor: pointer;
  }
  .flag-select .country-flag .country-label {
    font-size: 1em;
    position: relative;
  }
  .flag-select .flag-options {
    position: absolute;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #ffffff;
    margin-top: 8px;
    margin-left: -150px;
    max-height: 250px;
    height: 250px;
    max-width: 220px;
    width: 220px;
    overflow: auto;
  }
  .flag-select .flag-options.to--left {
    right: 10px;
  }
  .flag-select .flag-option {
    cursor: pointer;
    padding: 0px 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap;
  }
  .flag-select .flag-option.has-label {
    padding: 0px 20px 3px 8px;
  }
  .flag-select .flag-option:hover {
    background: #eaeaea;
  }
  .flag-select .flag-option:focus {
    outline: none;
    background: #eaeaea;
  }
  .flag-select .flag-option .country-label {
    padding: 0 9px;
  }
  .flag-select .filterBox {
    width: 100%;
  }
  .flag-select .filterBox input {
    width: 90%;
    margin: 0 4%;
  }
  .flag-select .filterBox input:focus {
    outline: none;
  }
  .flag-select .arrow-down {
    color: #4d4d4d;
    padding-left: 2px;
  }
  .flag-select .hidden {
    display: none;
  }
  .flag-select .no--focus {
    pointer-events: none;
  }
  .flag-select .fr-flag {
    background: url(../../../styles/images/fr.png);
    width: 16px;
    height: 11px;
    display: inline-block;
  }
  .flag-select .us-flag {
    background: url(../../../styles/images/us.png);
    width: 16px;
    height: 11px;
    display: inline-block;
  }
  .flag-select .gb-flag {
    background: url(../../../styles/images/gb.png) no-repeat;
    background-size: contain;
    width: 16px;
    height: 11px;
    display: inline-block;
  }
  .flag-select .ca-flag {
    background: url(../../../styles/images/ca.png) no-repeat;
    background-size: contain;
    width: 16px;
    height: 11px;
    display: inline-block;
  }
  .flag-select .de-flag {
    background: url(../../../styles/images/de.svg) no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-position-y: 5px;
    display: inline-block;
  }
  .flag-select .hu-flag {
    background: url(../../../styles/images/hu.svg) no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-position-y: 5px;
    display: inline-block;
  }
  .flag-select .it-flag {
    background: url(../../../styles/images/it.svg) no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-position-y: 5px;
    display: inline-block;
  }
  .flag-select .nl-flag {
    background: url(../../../styles/images/nl.svg) no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-position-y: 5px;
    display: inline-block;
  }
  .flag-select .pl-flag {
    background: url(../../../styles/images/pl.svg) no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-position-y: 5px;
    display: inline-block;
  }
  .flag-select .pt-flag {
    background: url(../../../styles/images/pt.svg) no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    background-position-y: 5px;
    display: inline-block;
  }
  .flag-select .country-flag > div {
    position: relative;
    top: 5px;
  }
}

@import '../../components/App/style-variables.scss';

.post-card-container{
  font-size: 16px;
  padding-top: 60px;

  .post-card-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }
  
  .material-icons {
    &.add_box,
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .post-card-details {
    .card {
      &:hover {
        background-color: $rgis-white;
        cursor: auto;
      }
    }
  }
}

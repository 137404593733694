@import '../../components/App/style-variables.scss';

.public-profile-header {
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
}

.public-profile-container{
  font-size: 16px;
  padding-top: 75px;

  .nav-tabs {
    background-color:#ffffff !important;
  }

  .rgis-values {
    background: url(../../styles/images/wis-values.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    min-height: 40px;
  }

  .material-icons {
    &.arrow-back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }
  
  .card {
    margin-bottom: 5px;

    .card-body {
      padding: 12px 16px 16px;
    }

    .card-title {
      font-weight: 600;
      font-size: 18px;
    }

    .section {
      margin-bottom: 18px;
    }

    .heading {
      margin-bottom: 5px;
      color: $rgis-medium-gray;
      font-size: 0.875rem;
      text-transform: capitalize;
    }

    .more-badges{
      background-color: $rgis-medium-gray;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border-color: 1px solid $rgis-light-gray;
      color: #ffffff;
      font-size: 20px;
    }


    .no-recent-badges-text {
      color: $rgis-blue;
    }
    
    .person-details {
      color: $rgis-black;
      font-size: 16px;
    
      .personal-info {
        .avatar-container {
          height: 70px;
          width: 70px;
          .user-avatar-image {
            min-height: 70px;
            height: 70px;
            min-width: 70px;
            width: 70px;
          }
        }
    
        .name {
          font-size: 18px;
        }
        .rank,
        .phone,
        .email,
        .organization-name {
          color: $rgis-medium-gray;
        }
    
        .rank {
          font-weight: 600;
        }
    
      }
      .core-value1 {
        background: url(../../styles/images/cv-service-1.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value2 {
        background: url(../../styles/images/cv-pride-2.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value3 {
        background: url(../../styles/images/cv-integrity-3.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value4 {
        background: url(../../styles/images/cv-results-4.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value5 {
        background: url(../../styles/images/cv-innovation-5.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value6 {
        background: url(../../styles/images/cv-teamwork-6.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }

      .core-value1-fill {
        background: url(../../styles/images/cv-service-blue.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value2-fill {
        background: url(../../styles/images/cv-pride-blue.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value3-fill {
        background: url(../../styles/images/cv-integrity-blue.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value4-fill {
        background: url(../../styles/images/cv-results-blue.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value5-fill {
        background: url(../../styles/images/cv-innovation-blue.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }

      .core-value6-fill {
        background: url(../../styles/images/cv-teamwork-blue.svg);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value-icons
      {
        background-size: 40px 40px;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
      }
    
      .core-value-counts
      {
        min-width: 40px;
        height: 40px;
        width: 40px;
        align-content: center;
        font-size: 14px;
      }
    }


  }
}

@media screen and(min-width: 992px) {
  .person-details {
    .personal-info {
      .avatar {
        margin-top: 14px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .public-profile-container{
    .compliment-btn {
      font-size: 12px;
    }
  }
}


@import '../../components/App/style-variables.scss';

.compliments-item {
    font-size: 18px;
}

.compliment-details {
    font-style: italic;
}

.dropleft {
    img{
        height: 15px;
    }
}
.compliment-actions{
    position: absolute;
    right: 0;
    margin-top: -11px;

    .compliment-actions-overflow {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .compliment-actions-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $rgis-light-gray;
        }
    }
}

.dropdown-menu {
    z-index: 9;
}
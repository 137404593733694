@import '../../components/App/style-variables.scss';
.retry-schedule-button {
  cursor: pointer;
  border-bottom: 1px solid #464547;
}

.schedule-content {
  border-top: 1px solid #999999;
  font-size: 16px;
  padding-top: 120px;
  position: relative;

  .card {
    margin: 9px 0;
  }

  .schedule-item-date {
    display: flex;
    font-size: 14px;
    justify-content: center;

    &.bold {
      font-weight: 1000;
    }
  }

  .material-icons {
    margin-right: 20px;

    &.fiber_manual_record {
      color: $rgis-green;
      margin-top: -9px;
      margin-right: 0;
      right: -10px;
      position: absolute;

      &.mobile {
        margin-left: 11px;
        margin-top: -8px;
        right: initial;
      }
    }
  }
}

@media screen and(min-width: 992px) {
  .schedule-content {
    padding-top: 60px;

    .schedule-item-date {
      justify-content: left;
      margin-left: 8px;
      margin-top: 24px;
    }
  }

  .supervisor-phone-call {
    display: none;
  }
  .schedule-date-interval {
    display: flex;
    justify-content: flex-end;
    font-weight: 800;
    margin: 16px 0 0;
  }
  .confirm-all-events {
    display: flex;
    align-items: center;
    margin: 16px 0 0;
  }
}

@media screen and(max-width: 991px) {
  .schedule-date-interval {
    display: none;
  }
  .confirm-all-events {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and(max-width: 395px) {
  .schedule-card-date {
    padding-right: 0;
  }
}

@media screen and(max-width: 425px) {
  .schedule-content .schedule-card-details .schedule-card-buttons-wrapper button {
    font-size: 0.8rem;
  }
}

@media screen and(max-width: 374px) {
  .logo {
    height: 29px;
  }
  .schedule-content {
    .schedule-card-details .schedule-card-buttons-wrapper button {
      font-size: 0.7rem;
    }
    .schedule-card-status.available .editStatus-icon {
      right: 10px;
    }
    .col.no-padding {
      text-align: left;
    }
  }
  .schedule-confirm-week-button {
    float: none;
    margin: 0 0 20px 50px;
  }
}

.schedule-card a:hover {
  color: white;
  text-decoration: none;
}

.no-overflow-modal {
  max-height: 1vh;
  overflow: hidden;
}

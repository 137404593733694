/* Variables */
@import '../App/style-variables.scss';

.other-availability-component {
    .general-title {
        color: $rgis-medium-gray;
        font-size: 16px;
        padding: 14px 18px 14px 16px;
    }
        
    .other-availability-link {
        color: $rgis-blue !important;
    }

    .other-avail-icon {
        vertical-align: bottom;
    }

    .other-avail-date-separator {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
    }

    .request-button {
        padding-top: 8px;
        text-align: center;


        .btn {
            background-color: $rgis-blue;
            border-color: $rgis-blue;
            border-radius: 24px;
            font-weight: 600;
            height: 48px;
            width: 325px;
        }
    }
}
@import '../../components/App/style-variables.scss';

.btn-refresh:hover {
  cursor: pointer;
}

.div-refresh {
  height: 25px;
  line-height: 25px;
  text-align: left;
}

.span-refresh {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-bottom: 15px;
  margin-left: 5px;
}

.event-chat-container{
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  resize: vertical;

  .chat-messages-list{
    .unread-messages{

      .unread-text{
        color: $rgis-blue;
        font-size: 14px;
      }
      .unread-line{
        width: 21%;
        height: 2px;
        background-color: $rgis-blue;
      }
    }
  }
  .chat-add-message-bar{
    position: sticky;
    bottom: 0px;
    left: 0px;
    background-color: $rgis-background;

    .scroll-to-bottom{
      position: absolute;
      color: $rgis-blue;
      width: 25px;
      height: 25px;
      
      i{
        font-size: 25px;
      }
      left: calc(50% - 12.5px);
      bottom: 50px;
      cursor: pointer;
      &:hover {
        background-color: $rgis-light-gray;
      }
    }

    .upload-error{
      color: $rgis-red;
    }
  
    .spinner-border{
      width: 20px !important;
      height: 20px !important;
    }

    .file-preview{
      height: 30px;
    }

    img.paperclip{
      width: 14px;
    }
    
    .send-message{
      i.send{
        &.disabled{
          opacity: 0.5;
          cursor: not-allowed;
        }
        color: $rgis-blue;
      }
    }
  }
}
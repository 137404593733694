@import '../../App/style-variables.scss';

.no-recent-badges-text {
  color: $rgis-blue;
}

.person-details {
  color: $rgis-black;
  font-size: 16px;

  .personal-info {
    .avatar-container {
      min-height: 70px;
      min-width: 70px;
      height: 70px;
      width: 70px;
      margin: 0;
      .initial-avatar {
        line-height: 70px;
        font-size: 2em;
      }
      .user-avatar-image {
        border-radius: 50%;
        min-height: 70px;
        height: 70px;
        min-width: 70px;
        width: 70px;
      }
    }

    .name {
      font-size: 18px;
    }
    .rank,
    .phone,
    .email {
      color: $rgis-medium-gray;
    }

    .rank {
      font-weight: 600;
    }

  }

  .heading {
    font-size: 14px;
  }

  .core-value1 {
    background: url(../../../styles/images/cv-service-1.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value2 {
    background: url(../../../styles/images/cv-pride-2.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value3 {
    background: url(../../../styles/images/cv-integrity-3.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value4 {
    background: url(../../../styles/images/cv-results-4.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value5 {
    background: url(../../../styles/images/cv-innovation-5.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value6 {
    background: url(../../../styles/images/cv-teamwork-6.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value1-fill {
    background: url(../../../styles/images/cv-service-blue.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value2-fill {
    background: url(../../../styles/images/cv-pride-blue.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value3-fill {
    background: url(../../../styles/images/cv-integrity-blue.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value4-fill {
    background: url(../../../styles/images/cv-results-blue.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value5-fill {
    background: url(../../../styles/images/cv-innovation-blue.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value6-fill {
    background: url(../../../styles/images/cv-teamwork-blue.svg);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value-icons
{
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .core-value-counts
  {
    min-width: 40px;
    height: 40px;
    width: 40px;
    align-content: center;
    font-size: 14px;
  }
}

@media screen and(min-width: 992px) {
  .person-details {
    .personal-info {
      .avatar {
        margin-top: 14px;
      }
    }
  }
}

@import '../../App/style-variables.scss';

.not_scheduled {
  margin: 8px;
  padding: 9px;

  &.card {
    background-color: #ededed;
    border-color: $rgis-medium-gray;
    box-shadow: none;

    .card-body {
      background-color: #ededed;
      border-radius: 3px;
      padding: 9px;

      .row {
        align-items: center;
      }
    }
  }

  .not_scheduled_text {
    display: flex;
    justify-content: flex-start;
    padding: 0;
  }

  .edit_status_icon {
    display: flex;
    justify-content: flex-end;

    &.empty {
      padding: 4px;
    }

    button {
      padding: 0;
      border: none;
      background: inherit;
    }
  }
}

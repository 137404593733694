@import '../../components/App/style-variables.scss';

.select-compliment-people-modal{
  .search-bar {
    .input-group {
      display: flex;
      align-items: center;
      .search-dropdown{
        position: absolute;
        z-index: 100;
        width: 100%;
        top: 41px;

        ul{
          padding: 0;
          box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        }
      }
      
      .spinner-border {
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 0.8rem;
        border: 0.15em solid currentColor;
        border-right-color: transparent;
        z-index: 5;
      }

      .material-icons.clear {
        position: absolute;
        right: 0.5rem;
        z-index: 5;
      }
    }
  }

  .organization-name {
    color: $rgis-medium-gray;
  }
}
@import '../../components/App/style-variables.scss';

.compliments-container{
  font-size: 16px;
  padding-top: 60px;

  .compliments-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .compliments-width{ 
    width: 75%;    
  }

  .card {
    margin-bottom: 10px;
   
    .card-body {
      padding: 12px 16px 16px;
    }

    a {
      color: $rgis-black;
      text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }
  .material-icons.add_box {
    font-size: 30px;
    height: 30px;
    width: 30px;
  }

  .core-value-image {
    height: 40px;
    width: 40px;
    max-width: 40px;
    padding-right: 2.5px;
    padding-left: 2.5px;
    float: left;
  }

  .core-value-count {
    text-align: center;
  }

  .core-value-integrity {
    background: url(../../styles/images/cv-integrity-3.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-pride {
    background: url(../../styles/images/cv-pride-2.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-service {
    background: url(../../styles/images/cv-service-1.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-teamwork {
    background: url(../../styles/images/cv-teamwork-6.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-results {
    background: url(../../styles/images/cv-results-4.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-innovation {
    background: url(../../styles/images/cv-innovation-5.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-integrity-selected {
    background: url(../../styles/images/cv-integrity-circle-filled.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-pride-selected {
    background: url(../../styles/images/cv-pride-circle-filled.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-service-selected {
    background: url(../../styles/images/cv-service-circle-filled.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-teamwork-selected {
    background: url(../../styles/images/cv-teamwork-circle-filled.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-innovation-selected {
    background: url(../../styles/images/cv-innovation-circle-filled.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }

  .core-value-results-selected {
    background: url(../../styles/images/cv-results-circle-filled.svg);
    background-size: 38px 38px;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 768px) {
  .compliments-container{
    .compliments-width{ 
      width: 100%;    
    }
  }
}
@import '../../components/App/style-variables.scss';
/* FULL PAGE MODAL */



/* END FULL PAGE MODAL */

/* REQUEST CHANGE */

.request-title {
  font-size: 15px;
  margin-bottom: 20px;
}

.request-reason {
  position: relative;
  * {
    cursor: pointer;
  }
  input[type='radio'] {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 10;
    outline: none;
    opacity: 0;
    margin-right: 10px;
    &:checked+ {
      .round-checkbox {
        border-color: $rgis-blue;
      }
      .round-checkbox::before {
        content: '';
        width: 10px;
        height: 10px;
        background: $rgis-blue;
        display: block; //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 50%;
        position: relative;
        top: 3.5px;
        left: 3.5px;
      }
    }
  }
}

.request-response {
  font-weight: bold;
  font-size: 16px;
  .approved-response {
    font-size: 18px;
    margin-left: 10px;
    color: #79ce3a;
  }
  .rejected-response {
    font-size: 18px;
    margin-left: 10px;
    color: #990033;
  }
  .pending-response {
    font-size: 18px;
    margin-left: 10px;
    color: #c2982d;
  }
}

.round-checkbox {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1.5px solid #999999;
  top: 5px; //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
}

.request-reason-label {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  margin-top: 7px;
}

.dropdown {
  margin: 20px 0;
  font-size: 14px;
  .dropdownTrigger {
    color: #000;
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: 1px solid #999999;
  }
  .dropdownContent {
    background: #fff; //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 2px 2px #efefef;
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    z-index: 3;
    >div {
      padding: 10px;
      cursor: pointer;
    }
  }
}

.eventDetails-content .request-response-info {
  padding-left: 20px;
  font-weight: bold;
  padding-top: 10px;
  margin-bottom: 10px;
}

p.sub-text {
  padding-left: 10px;
}

/* END REQUEST CHANGE */

.team-district-list {
  .separator {
    margin: 0;
    height: 2px;
  }
  .separator.separator-stuck {
    margin-top: -6px;
    border-width: 2px;
  }
  .row {
    margin: 0;
  }
  .district-title {
    font-weight: bold;
    margin: 10px 0;
    font-size: 15px;
    display: block;
  }
  .team-district-employee {
    padding: 5px;
    .employeeName {
      font-weight: bold;
      clear: both;
      display: block;
      color: #000;
    }
    span {
      color: #999;
    }
    .team-district-employee-user {
      text-align: center;
      svg {
        margin: 0 auto;
        color: #fff;
        background: #7c7c7c;
        padding: 4px;
        font-size: 25px;
        border-radius: 50%;
        margin-top: 5px;
      }
    }
    .team-district-employee-details {
      border-bottom: 1px solid #ccc;
      padding: 4px 0;
      svg {
        position: absolute;
        right: 10px;
        font-size: 18px;
        top: 10px;
      }
    }
  }
}
@import '../App/style-variables.scss';

.event-chat-message{
  .media-fancybox{
    div{
      position: fixed !important;
      z-index: 10000;
    }
  }
  
  .chat-message-actions {
    display: none;
  }

  .add-margin-end{
    margin-left: 10%;
  }
  .add-margin-start{
    margin: auto;
  }
  .personal-info{
    .avatar-container {
      min-height: 40px;
      min-width: 40px;
      height: 40px;
      width: 40px;
      margin: 0;

      .user-avatar-image {
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
      }

      .initial-avatar{
        font-size: 1em;
        line-height: 40px;
      }
    }
  }
  
  .chat-person-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 16px;

    .name {
      font-size: 14px;
    }
    .organization-name {
      font-size: 13px;
    }
    // margin-left: calc(40px + 0.5rem + 12px);
  }
  .chat-creation-date{
    font-size: 12px;
    padding: 10px 0;
    display: flex;
    max-width: fit-content;
  }

  .message-date {
    display: none;
    font-size: 14px;
    padding: 5px 8px 0;
  }

  .chat-message-wrapper {
    display: flex;
    min-width: 100%;
    flex-direction: column;

    &:hover {
      .chat-message-actions,
      .message-date {
        display: flex;
      }
    }
    &.clicked {
      .chat-message-actions,
      .message-date {
        display: flex;
      }
    }

    .chat-message {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 100%;
      margin-top: 4px;

      .chat-body{  
        background-color: $rgis-background;
        padding: 8px 8px 8px 12px;
        border-radius: 30px;
        word-break: break-word;
        max-width: 80%;

        &.editing{
          border: 2px solid $rgis-blue;
        }

        .chat-media{
          .image,
          .video{
            max-width: 100%;
            cursor: pointer;
            padding: 5px;
          }
        }
        img.paperclip{
          width: 14px;
        }
      }

      .chat-message-actions {
        cursor: pointer;

        .message-actions-button {
          min-width: 25px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: $rgis-light-gray;
          }
        }
      }
    }
  }

  .dropleft,
  .dropright {
    img{
      height: 15px;
    }
  }

  .reaction-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;

    .loading-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
    }

    .reaction-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 10px;

      &:hover {
        background-color: $rgis-light-gray;
        cursor: pointer;


        .reaction-like-image, .reaction-dislike-image{
          background-position-y: 37.3px;
        }
      }

      .reaction-like-image{
        background: url(../../styles/images/thumbs-up-solid.svg);
      }
      .reaction-dislike-image{
        background: url(../../styles/images/thumbs-down-solid.svg);
      }

      .reaction-like-image, .reaction-dislike-image{
        width: 17.5px;
        height: 17.5px;
        background-position-y: 0px;
        background-size: 100%; 

        &.green-color,
        &.red-color{
          background-position-y: 19px;
        }
      }
    }
  }  
}
@import '../../components/App/style-variables.scss';

.time-punch-details {
  border-top: 1px solid #999999;
  font-size: 16px;
  padding-top: 60px;
  position: relative;

  .time-punch-details-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .time-punch-info {
    a {
      color: inherit;
      text-decoration: none;
      &:hover{
        color: inherit;
        text-decoration: none;
      }
    }

    .job-name {
      font-size: 18px;
      font-weight: 600;
    }

    i.check-circle, i.msr {
      color: $rgis-blue;
    }
  }

  .assignment-icon,
  .car-icon {
    height: 40px;
    width: 40px;
    padding: 8px;
    background-color: $rgis-medium-gray;
    border-radius: 50%;
  }

  .cafe-icon {
    height: 40px;
    width: 40px;
    padding: 8px;
    border: 2px solid $rgis-green;
    border-radius: 50%;
  }

  .dining-icon {
    height: 40px;
    width: 40px;
    padding: 8px;
    border: 2px solid $rgis-blue;
    border-radius: 50%;
  }

  .time-punch {
    .name {
      font-size: 14px;
    }
    .vehicle-nr {
      font-size: 14px;
      color: $rgis-medium-gray;
    }
  }

  .travel-punch {
    .vehicle-nr {
      font-size: 14px;
    }
    .vehicle-description,
    .vehicle-type {
      font-size: 14px;
      color: $rgis-medium-gray;
    }
  }

  .lunch-break,
  .meal-break {
    padding: 5px 0;
  }
  
  .material-icons {
    &.add_box,
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .schedule-item-date {
    display: flex;
    font-size: 14px;
    justify-content: left;

    &.bold {
      font-weight: 1000;
    }
  }
}

/* Variables */
@import '../App/style-variables.scss';

.general-availability-component {
  
  .availability-edit-link {
    color: $rgis-blue !important;
  }

  .general-title {
    color: $rgis-medium-gray;
    font-size: 16px;
    padding: 14px 18px 14px 16px;
  }

  .general-avail-time-separator {
    padding-left: 10px;
    padding-right: 10px;
  }

  .general-calendar-item {
    padding: 12px 18px 12px 16px;

    .general-calendar-status {
      padding-right: 15px;
    }
  }

  .general-calendar-day {
    color: #13131c;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
  }

  .general-disclaimer {
    font-size: 14px;
    padding: 24px 0;
    text-align: center;
  }

  .request-button {
    padding-top: 8px;
    text-align: center;

    .btn {
      background-color: $rgis-blue;
      border-color: $rgis-blue;
      border-radius: 24px;
      font-weight: 600;
      height: 48px;
      width: 325px;
    }
  }
}

@import '../../App/style-variables.scss';

.avatar-container {
    min-height: 56px;
    min-width: 56px;
    height: 56px;
    width: 56px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;

    .initial-avatar {
        color: $rgis-white;
        line-height: 56px;
        font-size: 1.5em;
        text-align: center;
        border-radius: 50%;
    }

    .user-profile-image {
        min-height: 70px;
        min-width: 70px;
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }


    .avatarMPLOY {
        background-color: #990033;
    }

    .avatarSDAVZ {
        background-color: #256182;
    }

    .avatarHRFKUX {
        background-color: #4b905f;
    }

    .avatarBGTEI {
        background-color: #c2982d;
    }

    .avatarCWJNQNULL {
        background-color: #4f2170;
    }
}
@import '../App/style-variables.scss';

.request-change-button {
  display: flex;
  justify-content: center;
  margin: 22px 0;

  .btn {
    background-color: $rgis-blue;
    border-color: $rgis-blue;
    border-radius: 24px;
    height: 48px;
    width: 327px;
  }
}

/* Variables */

@import '../App/style-variables.scss';
/* DATE PICKER */

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: gray;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: $rgis-blue;
  color: #fff;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: $rgis-red;
  color: #fff;
}

.react-datepicker__day--highlighted:hover {
  background-color: gray;
}

.header-calendar {
  background: linear-gradient(to bottom, #4b4b63, #21212d);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  color: #fff;
  z-index: 1001;
  padding: 5px 20px;
  text-align: left;
}

.header-calendar .selected-year {
  font-size: 16px;
  font-weight: 500;
}

.header-calendar .selected-date {
  font-size: 35px;
  font-weight: bold;
  margin-top: 15px;
}

/* END DATE PICKER */

/* OVERWRITE REACT CSS */

.react-datepicker__portal {
  z-index: 1000;
}

.react-datepicker {
  position: fixed;
  padding: 10px 0;
  padding-top: 95px;
  top: 68px;
  border-radius: 0;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--highlighted {
  border-radius: 50%;
}

.react-datepicker__header {
  background: #fff;
  border: none;
  text-transform: capitalize;
}

.react-datepicker__portal .react-datepicker__current-month {
  font-size: 14px;
  color: #464547;
  margin-bottom: 10px;
}

.react-datepicker__portal .react-datepicker__day-name {
  font-weight: 600;
  font-size: 14px;
  color: #9f9f9e !important;
}

.react-datepicker__portal .react-datepicker__month {
  margin-top: 0;
  font-weight: 500;
  font-size: 14px;
  color: #7b7a7c !important;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.5rem solid transparent;
  margin-top: 95px;
}

.react-datepicker__portal button.react-datepicker__navigation--next {
  border-left-color: #474648;
}

.react-datepicker__portal button.react-datepicker__navigation--previous {
  border-right-color: #474648;
}

/* END OVERWRITE REACT CSS */

@import '../../components/App/style-variables.scss';

.feedback-container{
  color: $rgis-black;
  font-size: 16px;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 75%;

  .feedback-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .material-icons {
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .card {
    margin-bottom: 10px;

    .rating-stars{
      i{
        font-size: 30px;
        height: 30px;
        width: 30px;
        color: $rgis-medium-gray;
        z-index: 1000;
        &.rated-star{
          color: $rgis-yellow;
        }
      }
    }

    .jobName{
      font-size: 18px;
    }

    .no-avatar{
      border-radius: 50%;
      background-color: $rgis-medium-gray;
      width: 50px;
      height: 50px;
      i{
        font-size: 25px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .feedback-container{
      width: 100%;
  }
}
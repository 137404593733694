@import '../../components/App/style-variables.scss';

.profile-header {
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
}

.profile-container{
  font-size: 16px;
  padding-top: 60px;

  .nav-tabs {
    background-color:#ffffff !important;
  }

  .rgis-values {
    background: url(../../styles/images/wis-values.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    min-height: 40px;
  }

  .disclaimer-icon {
    vertical-align: bottom;
  }

  .button-wrapper {
    margin-top: 28px;
  }

  .btn {
    margin-top: 28px;
  }

  .nav-item {
    display: none;

    &.active {
      display: block;
    }

    & > .row > .col {
      padding: 4px 8px;
    }
  }

  .warning-label {
    font-weight: bold;
  }

  .nav-tabs {
    background-color: $rgis-light-gray;
    height: 48px;
    width: 100%;
    z-index: 1;
    
    .active {
      border-bottom: 4px solid $rgis-blue;
      font-weight: 600;
    }

    .nav-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }
  }

  .non-tabbed {
    display: none;

    & > .row > .col {
      padding: 4px;
    }
  }

  .header {
    font-size: 14px;
    padding: 64px 8px 8px;
    text-align: center;
  }

  .card {
    margin-bottom: 5px;

    .card-body {
      padding: 12px 16px 16px;
    }

    .card-title {
      font-weight: 600;
      font-size: 18px;
    }

    .section {
      margin-bottom: 18px;
    }

    .heading {
      margin-bottom: 5px;
      color: $rgis-medium-gray;
      font-size: 0.875rem;
      text-transform: capitalize;
    }

    .more-badges{
      background-color: $rgis-medium-gray;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border-color: 1px solid $rgis-light-gray;
      color: #ffffff;
      font-size: 20px;
    }
  }

  .table {
    thead {
      th {
        border-top: none;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .profile-container{
    .nav-tabs {
      .nav-item{
        font-size: 14px;
      }
    }
  }
}
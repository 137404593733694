@import '../../components/App/style-variables.scss';

.new-post-container{
  font-size: 16px;
  padding-top: 60px;
  padding-bottom: 60px;

  .channel-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  input[type='checkbox'] {
    &:hover {
      cursor: pointer;
    }
  }

  .upload-error{
    color: $rgis-red;
  }

  .spinner-border{
    width: 20px !important;
    height: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .new-post-container{
    .file-name {
      width: 65%;
    }
  }
}

@import '../../../components/App/style-variables.scss';

.badge-item{
    .badge-image{
      width: 100px;
    }
    .badge-image-small{
      width: 50px;
    }
    .additional-badge-image{
      opacity: 0.4;
      width: 50px;
      &:hover{
        opacity: 1;
      }
    }
}

.next-badges{
  font-size: 14px;
  text-transform: uppercase;
}

.earned-text{
  margin-right: -10px;
  margin-bottom: -5px;  
}

@media only screen and (max-width: 320px) {
  .badge-item{
    .badge-image{
      width: 80px;
    }
  }
}
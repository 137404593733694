/* Variables */

@import './style-datepicker-base.scss';
.date-picker-component {
  &.sticky-top {
    position: fixed;
    width: 100%;
    top: 60px;
    left: 0px;
    z-index: 9;
    box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.25);
    background-color: $rgis-light-gray;
  }

  .schedule-text {
    display: none;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  .date-picker-left {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 12px;
    margin-left: 12px;
    font-size: 16px;

    button.custom-trigger-date-picker {
      color: inherit;
      border: none;
      background: none;
    }

    .date-picker {
      display: flex;
    }
  }

  .date-picker-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      background-color: $rgis-light-gray;
      border: none;
      color: inherit;
      cursor: pointer;
      padding: 8px;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}

@media (min-width: 992px) {
  .date-picker-component {
    background-color: $rgis-background;

    &.sticky-top {
      background-color: $rgis-background;
      position: sticky;
    }

    > .row {
      margin: 0;
    }

    .schedule-text {
      display: block;
      margin: 20px 0;
      padding: 0;
    }
    .date-picker-left {
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }

    .date-picker-right {
      justify-content: flex-start;
      margin: 0;
      padding: 0;

      button {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        height: 24px;
        margin: 10px;
        padding: 0;

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
    }
  }
}

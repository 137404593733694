@import '../App/style-variables.scss';

.navigation-component {
  width: 100%;
  position: fixed;
  left: 0px;
  z-index: 10;

  .material-icons {
    margin-right: 20px;

    &.fiber_manual_record {
      color: $rgis-green;
      margin-top: -9px;
      margin-right: 0;
      right: -10px;
      position: absolute;

      &.mobile {
        margin-left: 11px;
        margin-top: -8px;
        right: initial;
      }
    }
  }

  .navbar-brand > a > img {
    height: 40px;
  }
  // Mobile Nav X Btn
  .navbar-toggler {
    color: $rgis-white;

    .navbar-toggler-icon {
      display: none;
    }
    .close-icon {
      color: $rgis-white;
      display: inherit;
    }

    &.collapsed {
      .navbar-toggler-icon {
        display: inherit;
      }
      .close-icon {
        display: none;
      }
    }
  }
  .dot-indicator {
    position: relative;
    .dot {
      height: 8px;
      width: 8px;
      background-color: #e31837;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 100px;
      top: -35px;
    }
  }

  nav {
    min-height: 60px;
    background-image: linear-gradient(to bottom, #4b4b63, #21212d);

    svg.svg-inline--fa {
      width: 35px;
    }

    .rgis-values {
      background: url(../../styles/images/wis-values.png);
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 40px;
    }

    .rschedule-icon {
      background: url(../../styles/images/wisschedule-icon.png);
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 20px;
      min-width: 20px;
      margin-right: 20px;
    }

    .navbar-rgis-values {
      background: $rgis-white;
    }

    .navbar-brand {
      padding-left: 52px;
      padding-right: 52px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .navbar-toggler {
      margin-left: 20px;
      border: none;
    }

    // Mobile notification indicator
    @media (max-width: 992px) {
      .notifications_none {
        color: $rgis-white;
      }
      .flag-select {
        margin: 15px 5px 15px 0px;
      }
    }

    .navbar-nav .nav-link {
      color: $rgis-white;
      font-size: 16px;
      padding-left: 1rem;
      padding-right: 1rem;
      &.active {
        color: $rgis-white;
        background-color: rgba(19, 19, 28, 0.3);
        margin-top: -15px;
        padding-top: 23px;
        margin-bottom: -15px;
        padding-bottom: 18px;
      }
    }
    .navbar-collapse.collapse.show {
      .extra-links {
        height: 100vh;
        li.nav-item a.nav-link {
          color: $rgis-blue;
        }
      }
    }

    .navbar-brand {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 52px;
      padding-right: 52px;
      padding-top: 0px;
      padding-bottom: 0px;

      @media (max-width: 992px) {
        padding-left: 4px;
      }
    }

    .notifications-indicator {
      margin-top: 5px;
      margin-right: 12px;
      .notifications {
        color: #e31837;
      }
    }

    .navbar-toggler {
      margin-left: 20px;
      border: none;
    }

    // Mobile version nav link
    .navbar-collapse.collapse.show .navbar-nav,
    .navbar-collapse.collapsing .navbar-nav {
      background: $rgis-white;

      .nav-item {
        padding-left: 16px;
        padding-right: 16px;

        .nav-link {
          padding-top: 12px;
          padding-bottom: 12px;
          color: black;

          .notifications_none {
            color: black;
          }

          &.active {
            background: #ededed;
            margin-top: 0px;
            margin-bottom: 0px;
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 30px;
            padding-right: 15px;
          }
        }

        .has-divider {
          border-bottom: 1px solid #cccccc;
        }
      }
    }
  }

  nav.navbar {
    padding: 0px;
  }
}

/* Variables */
@import '../../App/style-variables.scss';

.transportation-preferences-component {
  color: $rgis-black;
  font-size: 16px;

    .general-title {
        color: $rgis-medium-gray;
        font-size: 16px;
        padding: 14px 18px 14px 16px;
    }    

    .explanation-text {
        font-weight: bold;
        color: $rgis-medium-gray;
    }
  
    .transportation-preferences-link {
        color: $rgis-blue !important;
    }

    .transportation-preferences-icon {
        vertical-align: bottom;
    }    

    .search-by-name-textbox {
        margin-bottom: 15px;
    }

    .heading {
        font-size: 14px;
    }
}
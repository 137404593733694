@import '../App/style-variables.scss';

.component-event-details {
  color: $rgis-black;
  font-size: 16px;
  padding-top: 60px;

  .button-wrapper {
    margin-top: 28px;
  }

  .btn {
    margin-top: 28px;
  }

  .nav-item {
    display: none;

    &.active {
      display: block;
    }

    & > .row > .col {
      padding: 4px 8px;
    }
  }

  .nav-tabs {
    height: 48px;
    width: 100%;
    z-index: 1;

    .active {
      border-bottom: 4px solid $rgis-blue;
      font-weight: 600;
    }

    .nav-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .material-icons {
        margin-right: 20px;

        &.fiber_manual_record {
          color: $rgis-green;
          margin-top: -10px;
          right: -13px;
          position: absolute;
        }
      }
    }
  }

  .non-tabbed {
    display: none;

    & > .row > .col {
      padding: 4px;
    }
  }

  .header {
    font-size: 14px;
    padding: 30px 8px 8px;
    text-align: center;
  }

  .card {
    margin-bottom: 5px;

    .card-body {
      padding: 12px 16px 16px;
    }

    .card-title {
      font-weight: 600;
      font-size: 18px;
    }

    .section {
      margin-bottom: 18px;
    }

    .heading {
      margin-bottom: 5px;
      color: $rgis-medium-gray;
      font-size: 0.875rem;
      text-transform: capitalize;
    }
  }

  .table {
    thead {
      th {
        border-top: none;
      }
    }
  }

  @media (min-width: 992px) {
    // Desktop styles go here
    .header {
      border-bottom: 1px solid #cacacb;
      text-align: left;
      margin-bottom: 40px;
      padding-bottom: 28px;
      padding-top: 22px;

      .store-title {
        font-size: 40px;
        line-height: 40px;
        padding-bottom: 6px;
      }

      .date {
        color: $rgis-medium-gray;
        font-size: 20px;
        line-height: 20px;
      }
    }

    .tabbed {
      display: none;
    }

    .non-tabbed {
      display: block;
    }
  }

  .feedback-banner{
    background-color: $rgis-blue;
    a{
      color: $rgis-white
    }
  }
}

@media (max-width: 320px) {
  .row.header {
    font-size: 14px;
    padding: 5px 8px 8px;
    text-align: center;
  }
}

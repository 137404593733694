@import '../../components/App/style-variables.scss';

.open-shifts-container{
  font-size: 16px;
  padding-top: 60px;

  width: 75%;

  .open-shifts-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 576px) {
  .open-shifts-container{
    width: 100%;
  }  
}
@import '../../components/App/style-variables.scss';

.scroll-down{
  position: fixed;
  width: 50px;
  height: 50px;
  font-size: 50px;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

@import '../../App/style-variables.scss';

.open-shift-card {
  margin-bottom: 20px;

  .customer-name {
    font-size: 18px;
    font-weight: 600;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .shift-time {
    font-weight: 600;
  }

  .driver {
    color: #59b962;
  }

  .supervisor {
    color: #0072cb;
  }
}
@import '../App/style-variables.scss';

.app-download-modal-component {
  color: $rgis-black;
  font-size: 16px;

  .readonly-textbox {
    background-color: lightgray;
    width: 305px;
  }

  .copy-button {
    vertical-align: bottom;
    width: 50px;
    margin-left:10px;
  }

  .ios-chrome-instructions {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .rconnect-ios-en {
    background: url(../../styles/images/ios/download-en.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 83px;
  }
  .rconnect-ios-es {
    background: url(../../styles/images/ios/download-es.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 83px;
  }
  .rconnect-ios-fr {
    background: url(../../styles/images/ios/download-fr.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 83px;
  }

  .google-play-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 83px;
  }

  .btn-rgis-blue {
    background: $rgis-blue;
    color: #fff;  
    border-radius: 0px;
    font-weight: bold;
  }

  .copied-text-hidden {
    visibility: hidden;
  }

  .copied-text-visible {
    visibility: visible;
  }

}

@import '../../components/App/style-variables.scss';

.notification-details-container{
  font-size: 16px;
  padding-top: 60px;

  .notification-details-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .post-title {
    font-weight: bold;
  }

  .post-content {
    font-weight: normal;
  }

  .avatar-name-container {
    margin-left: 70px;
    overflow: hidden;
    max-height: 56px;
    min-height: 56px;
    text-align: left;
    padding-top: 15px;
  }

  .avatar-name {
    font-weight: bold;
  }
  
  .material-icons {
    &.add_box,
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .notification-details {
    .card {
      &:hover {
        background-color: $rgis-white;
        cursor: auto;
      }
      .card-body {
        h4, p {
          margin: 0
        }
      }
    }
  }

  .tg  {border-collapse:collapse;border-spacing:0;}
  .tg td{border-color:black;border-style:solid;border-width:1px;overflow:hidden;padding:10px 5px;word-break:normal;}
  .tg th{border-color:black;border-style:solid;border-width:1px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
  .tg .tg-gray-bg{text-align:right;background-color:#c0c0c0;font-weight:bold;vertical-align:top}
  .tg .tg-black-bg{text-align:right;background-color:#000000;color:#ffffff;vertical-align:top}
  .tg .tg-clear-bg{text-align:right;vertical-align:top}
  .tg .tg-align-left{text-align:left;}
  .tg .tg-red-bg{text-align:right;background-color:#cb0000;color:#ffffff;vertical-align:top}
  .tg .tg-yellow-bg{text-align:right;background-color:#f8ff00;color:#000000;vertical-align:top}
  .tg .tg-green-bg{text-align:right;background-color:#0a5714;color:#000000;vertical-align:top}
  .tg .tg-force-left{text-align:left !important;}
  .table-top-lvl{table-layout: fixed;font-family: Arial, sans-serif;}
  .table-top-lvl-td{padding:18px 0px 18px 0px;line-height:22px;text-align:left;}
}

@import '../../components/App/style-variables.scss';

.user-avatar-container{
  font-size: 16px;
  padding-top: 60px;
  padding-bottom: 40px;

  .user-avatar-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .material-icons.flag{
    position: absolute;
    top: 0px;
    right: 0;
  }

  .avatar-container {
    .user-avatar-image {
      width: 100%;
      height: 100%;
    }
  }
  
  .material-icons {
    &.add_box,
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .avatar-actions{
    position: relative;

    .dropdown-menu {
      z-index: 9;
    }

    .avatar-actions-button {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    img{
      height: 19px;
    }
  }

  .avatar-markup{
    position: relative;
    .avatar-container{
      min-height: 512px;
      min-width: 512px;
      height: 512px;
      width: 512px;
      .initial-avatar {
        line-height: 512px;
        font-size: 12em;
      }
    }
    img{
      border-radius: 50%;
    }

  }

  .crop-container {
    position: relative;
    height: 600px;
    display: flex;
    justify-content: center;

    .cropper-face,
    .cropper-view-box {
      border-radius: 50%;
      border: 1px solid rgba(255,255,255,0.5);
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
    .MuiSlider-root {
      color: $rgis-blue;
    }
  }

  .slider {
    padding: 22px 0px;
  }

  .upload-error{
    color: $rgis-red;
  }
}

@media screen and (max-width: 576px) {
  .user-avatar-container {
    .avatar-markup{
      .avatar-container{
        min-height: 280px;
        min-width: 280px;
        height: 280px;
        width: 280px;
        .initial-avatar {
          line-height: 280px;
          font-size: 6em;
        }
      }
    }
    .crop-container {
      height: 360px;
    }
  }
}
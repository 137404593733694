@import '../../components/App/style-variables.scss';

.channel-container{
  font-size: 16px;
  padding-top: 60px;
  .load-posts{
    margin-bottom: 10px;
  }

  .material-icons {
    &.add_box,
    &.arrow_back {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }

  .channel-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .post-actions{
    .dropdown-menu {
      z-index: 9;
    }

    .post-actions-button {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    img{
      height: 19px;
    }
  }

  .channel-posts-list{
    a{
      text-decoration: none;
      color: inherit;
      &:focus, &:hover, &:visited, &:link, &:active {
          text-decoration: none;
      }
    }
    .channel-post-item{
      background-color: #FFFFFF;
      border: 1px solid;
      border-color: #ccc;
      border-radius: 4px;
      cursor: pointer;
      &:hover{
        box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }

  .posts-loading {
    padding: 15px 0 30px;
  }
}

@import '../../components/Profile/Avatar/Avatar.scss';

.avatar-container{
    min-height: 56px;
    min-width: 56px;
    height: 56px;
    width: 56px;
    margin: 0;
    .initial-avatar{
      font-size: 1.5em;
      line-height: 56px;
    }
    .user-avatar-image {
      border-radius: 50%;
      min-height: 56px;
      height: 56px;
      min-width: 56px;
      width: 56px;
    }
  }

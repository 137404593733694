@import '../../../components/App/style-variables.scss';

.confirmation-popup {
  display: none;
  &.show {
    position: fixed;
    display: block !important;
    padding: 0 15px;
    background-color: rgba($color: #3f3f3f, $alpha: 0.8);
  }
  
  .modal-content {
    padding: 10px 20px;
  }
  .modal-backdrop {
    opacity: 0.5 !important;
  }
  p {
    margin: 0;
  }
  .modal-title {
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
  }
  .modal-header {
    padding: 10px 15px 15px;
    border: none;
  }
  .modal-body {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
  }
  .material-icons.warning {
    color: #fb0056;
    font-size: 40px;
    height: 40px;
    width: 40px;
  }
  .btn {
    width: 100px;
    text-transform: uppercase;
    cursor: pointer;
  }

  i{
    font-size: 30px;
    height: 30px;
    width: 30px;
  }

  .rating-stars{
    i{
      color: $rgis-medium-gray;
      z-index: 1000;
      &.rated-star{
        color: $rgis-yellow;
      }
    }
  }

}
  
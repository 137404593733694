$rgis-background: #ededed;
$rgis-white: #ffffff;
$rgis-red: #990033;
$rgis-orange: #f27707;
$rgis-yellow: #c2982d;
$rgis-blue: #0072cb;
$rgis-green: #5c9731;
$rgis-light-blue: #65a9aa;
$rgis-medium-gray: #5a5a5a;
$rgis-light-gray: #f7f7f7;
$rgis-black: #000000;

@import '../../components/App/style-variables.scss';

.file-upload{
  background-color: $rgis-background;
  cursor: pointer;

  &:hover {
    background-color: darken($color: $rgis-background, $amount: 3);
  }

  .dropzone{
    img{
      height: 30px;
    }
  }
}

@import '../../App/style-variables.scss';

.notification {
  .toast {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px 12px;
    margin-bottom: 8px;

    &.show {
      opacity: 1;
      transition: opacity 0.5s linear;
    }

    &.hide {
      opacity: 0;
      transition: opacity 1s linear;
    }

    &.success {
      background-color: #5c9731;
    }

    &.error {
      background-color: #e31837;
    }
  }

  @media screen and(min-width: 992px) {
    /*
    .fixed-top {
      top: 80px;
      left: 325px;
    }
    */
  }
}

@import '../../components/App/style-variables.scss';

.send-compliments-container{
  font-size: 16px;
  padding-top: 60px;

  .compliments-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .inline-avatars{
    .avatar-container{
      min-height: 30px;
      min-width: 30px;
      height: 30px;
      width: 30px;
      margin: 0;
      .initial-avatar {
        font-size: 1em;
        line-height: 30px;
      }
      .user-avatar-image {
        border-radius: 50%;
        min-height: 30px;
        height: 30px;
        min-width: 30px;
        width: 30px;
      }
    }
  }

  .card {
    margin-bottom: 10px;
   
    .card-body {
      padding: 12px 16px 16px;
    }
 
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }

  .organization-name {
    color: $rgis-medium-gray;
  }
}

@media only screen and (max-width: 768px) {
  .compliments-container{
    .compliments-width{ 
      width: 100%;    
    }
  }
}
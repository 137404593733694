@import '../../components/App/style-variables.scss';

.badges-container{
  font-size: 16px;
  padding-top: 60px;

  .badges-header {
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 10px 15px;
  }

  .badges-width{ 
    width: 75%;    
  }

  .card {
    margin-bottom: 10px;
   
    .card-body {
      padding: 12px 16px 16px;
    }

    a {
      color: $rgis-black;
      text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }
  .material-icons.add_box {
    font-size: 30px;
    height: 30px;
    width: 30px;
  }

}

@media only screen and (max-width: 768px) {
  .badges-container{
    .badges-width{ 
      width: 100%;    
    }
  }
}
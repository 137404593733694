@import '~bootstrap/dist/css/bootstrap.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-time-picker/dist/TimePicker.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
/* Variables */

@import './style-variables.scss';
/* Styles */

body {
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.4em;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  color: #414042;
  background: $rgis-background;
  font-size: 1rem;
  letter-spacing: 0.2px;
}

button {
  &:active,
  &:focus {
    outline: 0;
  }
}

.btn {
  font-size: 1rem;
  border-radius: 24px;
}

.cursor-pointer {
  cursor: pointer;
}

.noPadding {
  padding: 0 !important;
}

.paddingRight {
  padding-right: 15px !important;
}

.displayNone {
  display: none !important;
}

.visibleNone {
  visibility: hidden !important;
}

.underLine {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.btn-rgis-blue {
  background: $rgis-blue;
  color: #fff;  
  border-radius: 24px;

  &:hover {
    color: $rgis-white;
    background: darken($color: $rgis-blue, $amount: 3)
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      color: $rgis-white;
      background: $rgis-blue;
    }
  }
}

.btn-outline-secondary {
  &:focus {
    background: #fff;
    color: #6c757d;
    border-color: #6c757d;
  }
}

.btn-rgis-transparent {
  background: transparent;
  border: none;
  outline: none;
}

.text-red {
  color: $rgis-red;
}

.text-blue {
  color: $rgis-blue;
}

.semiBoldText {
  font-weight: 600 !important;
}

.boldText {
  font-weight: 700 !important;
}

.normalText {
  font-weight: 400 !important;
}

.smallText {
  font-size: 9px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.card {
  border-color: #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);

  .card-body {
    padding: 12px 16px;
  }
}

/* Material Icons Begin */
.material-icons {
  display: inline-flex;
  font-size: 20px;
  height: 20px;
  width: 20px;

  &.small {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }

  &.medium {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  &.large {
    font-size: 32px;
    height: 32px;
    width: 32px;
  }

  &.access_time,
  &.timelapse {
    margin-right: 7px;
  }

  &.map {
    color: $rgis-blue;
  }

  &.check-circle {
    color: $rgis-background;

    &.active {
      color: $rgis-blue;
    }
  }
}

.icon-label {
  align-items: center;
  display: flex;
}
/* Material Icons End */

.eventDetails-content .margin-bottom-20 {
  margin-bottom: 20px;
}

.headline_no_data {
  font-size: 20px;
  margin-top: 20px;
}

.animation-topToBottom {
  display: block;
  position: fixed;
  top: 100vh;
  opacity: 0;
  transition: 0.4s all linear !important;
}

.animation-topToBottom.show {
  opacity: 1;
  top: 0;
  transition: 0.4s all linear !important;
}

.fade.show {
  display: block;
  min-width: 100vw;
}

.note textarea {
  width: 100%;
  resize: none;
  font-size: 14px;
  padding: 10px;
  height: 88px;
  border-color: #999999;
  color: #999999;
  outline: none;
  margin-top: 10px;
}

.fullPageModal {
  width: 100%;
  height: 100%;
  display: block;
  .modal-body {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0; //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate(0) !important;
    .modal-content {
      background: #fff; //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      height: 100%;
      width: 100vw;
      .modal-header {
        background: linear-gradient(to bottom, #4b4b63, #21212d);
        color: #fff;
        padding: 25px 15px;
        border: none;
        h5 {
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          max-height: 32px;
          white-space: nowrap;
        }
        .close {
          //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          text-shadow: none;
          font-size: 2rem;
          color: #fff;
          opacity: 1;
          font-weight: 100;
        }
      }
    }
  }
  .modal-footer {
    border: none;
    padding-bottom: 0;
    position: relative;
    button {
      width: 95%;
      border: 1px solid #8f012f;
      padding: 15px 0;
      margin-bottom: 10px;
      position: absolute;
      bottom: 0;
      left: 2.5%;
    }
  }
}

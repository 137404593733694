@import '../App/style-variables.scss';

.team-member-info {
  a {
    display: block;
  }

  .status-description,
  .ranking {
    color: $rgis-medium-gray;
    font-size: 1rem;
  }

  .row {
    border-bottom: 1px solid #e8e8e8;
    height: 91px;
    padding: 10px 0;
    margin: 0;

    .col {
      &.text-right {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;

        span {
          padding-right: 10px;
        }
      }
    }
  }

  &:last-child .row {
    border-bottom: none;
  }
}

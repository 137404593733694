@import '../../../App/style-variables.scss';
.confirmation-pop-up {
  &.show {
    position: fixed;
    display: block !important;
    padding: 0 15px;
  }
  .margin-20 {
    margin-top: 20px;
  }
  .modal-content {
    padding: 10px 20px;
  }
  .modal-backdrop {
    opacity: 0.5 !important;
  }
  p {
    margin: 0;
  }
  .modal-title {
    font-weight: bold;
    text-align: center;
    margin-left: -1em;
  }
  .center-button {
    text-align: center;
  }
  .modal-header {
    padding: 10px 15px 15px;
    border: none;
  }
  .modal-body {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
  }
  .submit-button {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    font-weight: 600;
    background: $rgis-red;
    border: none;
  }
  .cancel-button {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .notify-button {
    width: 200px;
    font-weight: bold;
    background-color: #0072cb;
    border-color: #0072cb;
    border-radius: 24px;
    height: 48px;
    color: #ffffff;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
  }
}

.request-past-time {
  .material-icons.warning {
    color: #fb0056;
    font-size: 40px;
  }
}

.request-normal-time {
  .material-icons.warning {
    color: #f8bb43;
    font-size: 40px;
  }
}

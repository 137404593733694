.notification-container .card:last-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.notification-container .card:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

// This class is rendered by the parent component since
// the nested div cause isssues when using last/first child
.notification-list-component {
  font-size: 16px;
  .card {
    height: 80px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    border-bottom: 0px;
    a:hover {
      text-decoration: none;
    }
    .card-body {
      color: #13131c;
      cursor: pointer;
      p {
        margin: 0;
      }
    }
    &.read {
      .card-body {
        opacity: 0.5;
      }
    }
    &:hover {
      .card-body {
        opacity: 1;
      }
    }
  }
  img {
    border-radius: 50%;
    height: 56px;
    display: block;
  }
  .avatar-container {
    margin: 0;
  }
  .notification-title {
    font-weight: 600;
  }
}

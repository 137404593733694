@import '../App/style-variables.scss';

.store-data {
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f7f7f7;
  }

  .store-data-length {
    .col {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  .circle {
    align-items: center;
    border: 4px solid;
    border-radius: 31px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    justify-content: center;
    height: 62px;
    width: 62px;

    &.target-length {
      border-color: $rgis-green;
    }

    &.max-length {
      border-color: $rgis-orange;
    }
  }
}

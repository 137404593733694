@import '../../components/App/style-variables.scss';

.post-card-details{
  .card {
    margin-bottom: 10px;
    
    &.new-post-border{
      border-bottom: 3px solid $rgis-green;
    }

    &:hover {
      background-color: $rgis-light-gray;
      cursor: pointer;
    }

    .card-body {
      padding: 12px 16px 16px;
      word-break: break-word;

      .post-body {
        white-space: pre-wrap;
      }
    }

    .card-title {
      font-weight: 600;
      font-size: 18px;
    }

    .section {
      margin-bottom: 18px;
    }

    .personal-info{
      .name {
        font-size: 18px;
      }
      .rank,
      .phone,
      .email {
        color: $rgis-medium-gray;
      }
  
      .rank {
        font-weight: 600;
      }

      .material-icons.remove-red-eye {
        margin: 2px 30px 0 0;
      }

      .indicator-icon {
        height: 20px;
        width: 20px;
        margin: 2px 30px 0 0;
      }

      .post-actions{
        position: absolute;
        right: 0;
        margin-top: -11px;

        .dropdown-menu {
          z-index: 9;
        }

        .post-actions-button {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: $rgis-light-gray;
          }
        }

        img{
          height: 19px;
        }
      }
    }

    .social-bar{
      margin-top: 20px;

      .loading-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
        margin-bottom: -10px;
      }

      .social-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 10px;
        margin-right: 3px;
        margin-bottom: -10px;

        &:first-child {
          margin-left: -8px;
        }

        &:hover {
          background-color: $rgis-light-gray;
          cursor: pointer;
        }

        .social-like-image{
          background: url(../../styles/images/thumbs-up-solid.svg);
        }
        .social-dislike-image{
          background: url(../../styles/images/thumbs-down-solid.svg);
        }

        .social-like-image, .social-dislike-image{
          width: 20px;
          height: 20px;
          background-position-y: 0px;
          background-size: 100%; 

          &:hover {
            background-position-y: 42.3px;
          }

          &.green-color,
          &.red-color{
            background-position-y: 21px;
          }
        }
      }
    }   
    
    .post-media{
      .video,
      .image{
        max-height: 400px;
        max-width: 100%;
        cursor: pointer;
      }
    }
    .show-more-btn{
      font-weight: bold;
      color: $rgis-blue;
      cursor: pointer;
    }

    .paperclip{
      width: 14px;
    }

    .translate-bar{
      background-color: $rgis-light-gray;
      .original{
        color: $rgis-blue;
      }
    }
  }

  .scroll-down{
    position: fixed;
    width: 50px;
    height: 50px;
    font-size: 50px;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
  }
}

.fs-image{
  .close{
    font-size: 2rem;
  }
  img {
    max-height: 800px;
    max-width: 100%;
  }
}
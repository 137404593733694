@import '../../App/style-variables.scss';


.calendar-component {
  margin-left: -15px;
  @media (min-width: 768px) {
    width: 50%;
  }
  .material-icons{
    cursor: pointer;
  }
}

.calendar-text:hover{
  cursor: pointer;
}

.col.schedule-content-card-col {
  a {
    color: inherit;
    text-decoration: none;
  }

  .job-name-col {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .map-point-icon {
    margin-right: -10px;
  }

  .job-location-col {
    color: $rgis-medium-gray;
  }

  .confirmed-event-checkbox {
    align-items: center;

    button {
      border: none;
      background-color: inherit;
    }
  }

  .btn-confirm {
    margin-top: 5px;
  }

  .job-info {
    padding-top: 16px;

    .col {
      padding-right: 0;
    }
  }

  .chat-info {
    padding-top: 10px;
    color: $rgis-green;

    &.disabled {
      color: $rgis-medium-gray;
    }

    .col {
      padding-right: 0;
    }
  }
  .calendar-info {
    .spinner-border{
      margin-left: 8px;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      vertical-align: text-bottom;
      border: 0.15em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      -webkit-animation: spinner-border .75s linear infinite;
      animation: spinner-border .75s linear infinite;
    }
    padding-top: 10px;
    padding-bottom: 8px;
    .col {
      padding-right: 0;
    }
  }

  .meet-site-label {
    font-size: 14px;
    margin: 16px 16px 0 16px;
    border-top: 1px solid #eee;
    padding: 8px 8px 0;
  }

  .job-additional-info {
    font-size: 14px;
    margin: 16px 16px 0 16px;
    border-top: 1px solid #eee;
    padding: 8px 8px 0;
  }

  .store-info-icon {
    vertical-align: bottom;
    margin-left: -3px;
  }
}

.dropdownStyles{
  margin-top: -10px;
  padding: 0px 10px 10px 43px;
  width: 300px;
  a{
    display:block;
  }
  a:hover{
    text-decoration: underline;
  }
}

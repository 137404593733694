/* Variables */
@import '../../App/style-variables.scss';

.preferences-component {
  color: $rgis-black;
  font-size: 16px;

  .general-title {
    color: $rgis-medium-gray;
    font-size: 16px;
    padding: 14px 18px 14px 16px;
  }

  .preferences-edit-link {
    color: $rgis-blue !important;
  }

  .heading {
    font-size: 14px;
  }
}
@import '../../components/App/style-variables.scss';

.compliments-item-post-details {
  font-size: 10px;
}

.compliments-item {
  font-size: 18px;

  .card {
    margin-bottom: 10px;

    .card-body {
      padding: 12px 16px 16px;
      word-break: break-word;

      .post-body {
        white-space: pre-wrap;
      }
    }

    .card-title {
      font-weight: 600;
      font-size: 18px;
    }

    .section {
      margin-bottom: 18px;
    }

    .compliment-actions{
      position: absolute;
      right: 0;
      margin-top: -11px;

      .dropdown-menu {
        z-index: 9;
      }

      .compliment-actions-button {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: $rgis-light-gray;
        }
      }

      img{
        height: 19px;
      }
    }
  }
}

.people-count {
  padding-bottom: 18px;
}

.core-value-icons {
  padding-bottom: 18px;
}

.core-value-integrity {
  background: url(../../../src/styles/images/cv-integrity-circle.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-pride {
  background: url(../../../src/styles/images/cv-pride-circle.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-service {
  background: url(../../../src/styles/images/cv-service-circle.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-teamwork {
  background: url(../../../src/styles/images/cv-teamwork-circle.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-innovation {
  background: url(../../../src/styles/images/cv-innovation-circle.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-results {
  background: url(../../../src/styles/images/cv-results-circle.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-integrity-fill {
  background: url(../../../src/styles/images/cv-integrity-circle-filled.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-pride-fill {
  background: url(../../../src/styles/images/cv-pride-circle-filled.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-service-fill {
  background: url(../../../src/styles/images/cv-service-circle-filled.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-teamwork-fill {
  background: url(../../../src/styles/images/cv-teamwork-circle-filled.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-innovation-fill {
  background: url(../../../src/styles/images/cv-innovation-circle-filled.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}

.core-value-results-fill {
  background: url(../../../src/styles/images/cv-results-circle-filled.svg);
  background-size: 36px 36px;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
}